<template>
    <SignUp></SignUp>
    <Footer v-if="!isMobile"></Footer>
</template>

<script>
  import SignUp from '@/components/user/SignUp.vue'
  import Footer from '@/components/footer/Footer.vue'

import {mapGetters,mapActions} from 'vuex'
  export default {
    components: {
        SignUp,
        Footer
    },
      computed: {
        ...mapGetters([
            'isMobile'
        ]),
    },
  }
</script>

